<template>
  <div
    class="
      bg-white
      drop-shadow-xl
      rounded-[35px]
      mt-12
      py-[50px]
      md:py-[102px] md:px-[108px]
      xl:px-[125px]
      max-w-[792px]
      mx-auto
    "
  >
    <h4
      class="
        text-lg
        md:text-[26px]
        text-deepBlue-500 text-center
        font-bold
        px-9
        md:px-0
        mb-6
        md:mb-12
      "
    >
      Reviewing your answers...
    </h4>
    <div class="flex justify-center items-center">
      <img class="h-[179px] w-[179px]" src="../../assets/loading.gif" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Quiz5",
};
</script>