<template>
  <section class="md:py-[50px] xl:pt-[112px] pb-[50px] xl:pb-[100px]">
    <div class="max-w-6xl mx-auto px-10">
      <h3
        class="
          text-xl
          md:text-[26px]
          xl:text-[32px]
          text-center text-deepBlue-500
          font-ubuntu font-bold
          mb-[25px]
          md:mb-[50px]
        "
      >
        These health benefits are available to you!
      </h3>
      <div
        class="
          grid grid-cols-1
          md:grid-cols-3
          xl:grid-cols-12
          gap-y-[25px]
          md:gap-y-[50px]
          xl:gap-y-[78px]
        "
      >
        <div class="flex flex-col xl:col-span-3 mb-6">
          <img
            class="h-[200px] w-[200px] mx-auto"
            src="../assets/heart.gif"
            alt=""
          />
          <p class="text-lg text-center text-body font-ubuntu font-medium -mt-7"
            >Health Coverage</p
          >
        </div>
        <div class="flex flex-col xl:col-span-3">
          <img
            class="h-[200px] w-[200px] mx-auto"
            src="../assets/ambulance.gif"
            alt=""
          />
          <p class="text-lg text-center text-body font-ubuntu font-medium -mt-7"
            >Emergency Care</p
          >
        </div>
        <div class="flex flex-col xl:col-span-3">
          <img
            class="h-[200px] w-[200px] mx-auto"
            src="../assets/medication.gif"
            alt=""
          />
          <p class="text-lg text-center text-body font-ubuntu font-medium -mt-7"
            >Prescriptions</p
          >
        </div>
        <div class="flex flex-col xl:col-span-4">
          <img
            class="h-[200px] w-[200px] mx-auto"
            src="../assets/iv.gif"
            alt=""
          />
          <p class="text-lg text-center text-body font-ubuntu font-medium -mt-7"
            >Pre-Existing Conditions</p
          >
        </div>
        <div class="flex flex-col xl:col-span-4">
          <img
            class="h-[200px] w-[200px] mx-auto"
            src="../assets/doctor.gif"
            alt=""
          />
          <p class="text-lg text-center text-body font-ubuntu font-medium -mt-7"
            >Mental Health</p
          >
        </div>
        <div class="flex flex-col md:col-span-3 xl:col-span-4">
          <img
            class="h-[200px] w-[200px] mx-auto"
            src="../assets/hospital.gif"
            alt=""
          />
          <p class="text-lg text-center text-body font-ubuntu font-medium -mt-7"
            >Hospitalizations</p
          >
        </div>
      </div>
    </div>
  </section>
</template>
