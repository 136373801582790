<template>
  <Navbar :phone-number-link="phoneNumberLink" :phone-number-formatted="phoneNumberFormatted" />
  <div
    class="bg-right-top bg-no-repeat bg-hero-mobile md:bg-hero-tablet xl:bg-hero-desktop"
  >
    <div
      class="bg-center bg-cover bg-mobile md:bg-tablet xl:bg-desktop after:bg-bg-hero"
    >
      <Hero />
      <Question :phone-number-link="phoneNumberLink" :phone-number-formatted="phoneNumberFormatted" />
      <Benefits />
      <About :phone-number-link="phoneNumberLink" :phone-number-formatted="phoneNumberFormatted" />
      <Help />
      <Here :phone-number-link="phoneNumberLink" :phone-number-formatted="phoneNumberFormatted" />
      <CallToAction :phone-number-link="phoneNumberLink" :phone-number-formatted="phoneNumberFormatted" />
    </div>
  </div>
  <Footer :phone-number-link="phoneNumberLink" :phone-number-formatted="phoneNumberFormatted" />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Hero from "@/components/Hero.vue";
import Question from "@/components/Question.vue";
import CallToAction from "@/components/CallToAction.vue";
import Footer from "@/components/Footer.vue";
import Benefits from "@/components/Benefits.vue";
import About from "@/components/About.vue";
import Help from "@/components/Help.vue";
import Here from "@/components/Here.vue";

export default {
  components: {
    Navbar,
    Hero,
    Question,
    CallToAction,
    Footer,
    Benefits,
    About,
    Help,
    Here,
  },
  props: {
    phoneNumberLink: {
      type: String,
      required: true,
    },
    phoneNumberFormatted: {
      type: String,
      required: true,
    },
  }
}
</script>
