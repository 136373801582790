<template>
  <svg
    width="250"
    height="51"
    viewBox="0 0 250 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.7899 8.81C33.7899 3.945 29.8449 0 24.9799 0C20.1149 0 16.1699 3.945 16.1699 8.81V16.16H33.7949V8.81H33.7899Z"
      fill="#A2A7DA"
    />
    <path d="M33.79 33.785H16.165V49.95H33.79V33.785Z" fill="#27CDCC" />
    <path d="M49.955 16.16H33.79V33.785H49.955V16.16Z" fill="#7DE1E0" />
    <path
      d="M8.81 16.16C3.945 16.16 0 20.105 0 24.97C0 29.835 3.945 33.78 8.81 33.78H16.16V16.155H8.81V16.16Z"
      fill="#7A81CA"
    />
    <path d="M33.79 16.16H16.165V33.785H33.79V16.16Z" fill="#555A8D" />
    <path
      d="M54.9551 16.2249H63.2201C66.1101 16.2249 68.6751 17.4398 68.6751 20.7898C68.6751 22.0598 67.8901 23.3549 66.9451 23.8949C68.8351 24.3549 70.2701 26.0299 70.2701 28.3249C70.2701 32.0249 67.3501 33.7299 63.4901 33.7299H54.9551V16.2249ZM62.9501 22.9249C64.2751 22.9249 64.9201 22.2748 64.9201 21.2248C64.9201 20.1748 64.2701 19.4699 62.8651 19.4699H58.7301V22.9298H62.9451L62.9501 22.9249ZM63.3001 30.4899C65.1101 30.4899 66.3251 29.8398 66.3251 28.2748C66.3251 26.7098 65.1901 25.9799 63.5151 25.9799H58.7351V30.4899H63.3001Z"
      fill="#180C3C"
    />
    <path
      d="M72.3804 26.9748C72.3804 23.0848 75.4054 19.9498 79.3754 19.9498C83.0504 19.9498 85.9404 23.0848 85.9404 26.9198C85.9404 27.5698 85.8604 28.2148 85.8604 28.2148H76.0554C76.4854 30.0798 78.0254 30.9698 79.8104 30.9698C81.1054 30.9698 82.4604 30.5898 83.4554 29.9148L84.8054 32.5098C83.2904 33.5098 81.5354 33.9948 79.7804 33.9948C75.8354 33.9948 72.3804 31.4298 72.3804 26.9698V26.9748ZM82.5404 25.8148C82.2704 24.1148 80.9204 22.9798 79.2704 22.9798C77.6204 22.9798 76.3504 24.1148 76.0004 25.8148H82.5404Z"
      fill="#180C3C"
    />
    <path
      d="M87.6202 32.1648L88.9152 29.3798C89.8352 30.0798 91.1052 30.8098 93.1302 30.8098C93.9702 30.8098 95.3452 30.4848 95.3452 29.6748C95.3452 28.8648 94.1852 28.6748 93.0202 28.3498C90.3452 27.6498 88.1552 26.7548 88.1552 24.1648C88.1552 21.4098 90.8552 19.9498 93.3952 19.9498C95.6102 19.9498 97.0152 20.4898 98.4202 21.2998L97.2052 23.9998C95.8802 23.3498 94.6402 23.0548 93.4502 23.0548C92.6102 23.0548 91.8302 23.4348 91.8302 23.9998C91.8302 24.7548 92.9652 24.9998 94.2052 25.2698C96.5002 25.8098 99.0152 26.8098 99.0152 29.5648C99.0152 32.8898 95.6402 33.9948 93.1252 33.9948C90.2352 33.9948 88.3702 32.8598 87.6152 32.1598L87.6202 32.1648Z"
      fill="#180C3C"
    />
    <path
      d="M102.1 29.3798V23.1398H100.13V20.2198H102.1V17.6248L105.72 16.8148V20.2198H109.45V23.1398H105.72V29.3248C105.72 30.4848 106.285 30.8648 106.935 30.8648C107.8 30.8648 108.555 30.3798 108.665 30.2998L109.69 32.9748C108.88 33.5148 107.69 33.9998 106.26 33.9998C103.56 33.9998 102.1 32.4348 102.1 29.3798Z"
      fill="#180C3C"
    />
    <path
      d="M123.93 16.2249H127.66L135.28 33.7299H131.445L130.255 30.9448H121.365L120.175 33.7299H116.31L123.93 16.2249ZM129.065 28.0049L127.04 23.0049C126.5 21.6799 125.825 19.9249 125.795 19.8699C125.77 19.9249 125.095 21.6799 124.55 23.0049L122.525 28.0049H129.065Z"
      fill="#180C3C"
    />
    <path
      d="M137.49 23.1649H135.385V20.2449H137.49V19.3549C137.49 16.2199 139.22 14.8699 141.515 14.8699C142.73 14.8699 144.08 15.2199 145.025 15.7099L144 18.3849C143.705 18.2499 143.11 17.9799 142.485 17.9799C141.73 17.9799 141.105 18.3599 141.105 19.7649V20.2499H144.48V23.1699H141.105V33.7349H137.485V23.1699L137.49 23.1649Z"
      fill="#180C3C"
    />
    <path
      d="M146.57 23.1649H144.465V20.2449H146.57V19.3549C146.57 16.2199 148.3 14.8699 150.595 14.8699C151.81 14.8699 153.16 15.2199 154.105 15.7099L153.08 18.3849C152.785 18.2499 152.19 17.9799 151.565 17.9799C150.81 17.9799 150.185 18.3599 150.185 19.7649V20.2499H153.56V23.1699H150.185V33.7349H146.565V23.1699L146.57 23.1649Z"
      fill="#180C3C"
    />
    <path
      d="M154.43 26.9748C154.43 23.0598 157.62 19.9498 161.645 19.9498C165.67 19.9498 168.885 23.0548 168.885 26.9748C168.885 30.8948 165.695 33.9998 161.645 33.9998C157.595 33.9998 154.43 30.8948 154.43 26.9748ZM165.18 26.9748C165.18 24.8698 163.665 23.2998 161.64 23.2998C159.615 23.2998 158.1 24.8648 158.1 26.9748C158.1 29.0848 159.615 30.6498 161.64 30.6498C163.665 30.6498 165.18 29.1098 165.18 26.9748Z"
      fill="#180C3C"
    />
    <path
      d="M171.235 20.2198H174.8V22.8148C175.18 21.7048 176.555 19.9498 179.53 19.9498V23.8948C176.395 23.8948 174.855 25.3248 174.855 28.5398V33.7248H171.235V20.2148V20.2198Z"
      fill="#180C3C"
    />
    <path
      d="M180.985 26.9748C180.985 23.0848 183.415 19.9498 187.145 19.9498C188.71 19.9498 190.25 20.5698 191.225 21.7348V15.9548H194.845V33.7298H191.415V32.1098C190.44 33.4048 188.715 33.9998 187.09 33.9998C183.36 33.9998 180.985 30.8648 180.985 26.9748ZM191.225 26.9748C191.225 24.8698 189.875 23.2998 188.01 23.2998C186.145 23.2998 184.66 24.8648 184.66 26.9748C184.66 29.0848 186.065 30.6498 188.01 30.6498C189.955 30.6498 191.225 29.1348 191.225 26.9748Z"
      fill="#180C3C"
    />
    <path
      d="M197.195 26.9748C197.195 23.0848 199.57 19.9498 203.3 19.9498C204.865 19.9498 206.54 20.5698 207.515 21.8698V20.2198H211.055V33.7298H207.515V32.1098C206.57 33.4048 204.865 33.9998 203.3 33.9998C199.57 33.9998 197.195 30.8648 197.195 26.9748ZM207.435 26.9748C207.435 24.8698 206.085 23.2998 204.22 23.2998C202.355 23.2998 200.87 24.8648 200.87 26.9748C200.87 29.0848 202.275 30.6498 204.22 30.6498C206.165 30.6498 207.435 29.1348 207.435 26.9748Z"
      fill="#180C3C"
    />
    <path
      d="M217.245 32.1098V33.7298H213.815V15.9548H217.435V21.7348C218.41 20.5748 219.95 19.9498 221.515 19.9498C225.245 19.9498 227.675 23.0848 227.675 26.9748C227.675 30.8648 225.3 33.9998 221.57 33.9998C219.95 33.9998 218.245 33.4048 217.25 32.1098H217.245ZM224 26.9748C224 24.8698 222.595 23.2998 220.65 23.2998C218.705 23.2998 217.435 24.8648 217.435 26.9748C217.435 29.0848 218.815 30.6498 220.65 30.6498C222.595 30.6498 224 29.1098 224 26.9748Z"
      fill="#180C3C"
    />
    <path d="M230.02 15.9548H233.64V33.7298H230.02V15.9548Z" fill="#180C3C" />
    <path
      d="M235.995 26.9748C235.995 23.0848 239.02 19.9498 242.99 19.9498C246.665 19.9498 249.555 23.0848 249.555 26.9198C249.555 27.5698 249.475 28.2148 249.475 28.2148H239.67C240.105 30.0798 241.645 30.9698 243.425 30.9698C244.72 30.9698 246.07 30.5898 247.07 29.9148L248.42 32.5098C246.905 33.5098 245.15 33.9948 243.395 33.9948C239.45 33.9948 235.995 31.4298 235.995 26.9698V26.9748ZM246.155 25.8148C245.885 24.1148 244.535 22.9798 242.885 22.9798C241.235 22.9798 239.965 24.1148 239.615 25.8148H246.155Z"
      fill="#180C3C"
    />
    <path
      d="M54.9551 45.3049C54.9551 42.6599 57.0251 40.5449 59.7851 40.5449C61.0151 40.5449 62.1451 40.9899 62.9701 41.7299L62.3001 42.4999C61.6001 41.8999 60.7701 41.5849 59.8551 41.5849C57.7101 41.5849 56.1401 43.2549 56.1401 45.2999C56.1401 47.3449 57.7101 49.0449 59.7851 49.0449C60.8551 49.0449 61.8301 48.6149 62.5001 47.8999L63.2151 48.6849C62.3601 49.5399 61.1551 50.0849 59.7851 50.0849C57.0251 50.0849 54.9551 47.9699 54.9551 45.2999V45.3049Z"
      fill="#180C3C"
    />
    <path
      d="M73.9452 40.66H74.9752L79.3202 49.95H78.1602L77.3052 48.15H71.6302L70.7752 49.95H69.6052L73.9502 40.66H73.9452ZM76.8902 47.22L75.1902 43.62C74.8752 42.95 74.5202 42.06 74.4602 41.95C74.4052 42.065 74.0602 42.935 73.7302 43.62L72.0302 47.22H76.8902Z"
      fill="#180C3C"
    />
    <path
      d="M86.3002 40.6901H90.1002C92.6702 40.6901 94.0752 42.2501 94.0752 44.1751C94.0752 45.5051 93.3052 46.7051 91.9452 47.2501L94.1452 49.9501H92.7852L90.9002 47.5651C90.6552 47.6101 90.3152 47.6351 90.0002 47.6351H87.4402V49.9501H86.2952V40.6901H86.3002ZM90.1852 46.6501C91.7552 46.6501 92.8702 45.5351 92.8702 44.1801C92.8702 42.8251 91.7702 41.6801 90.2002 41.6801H87.4402V46.6551H90.1852V46.6501Z"
      fill="#180C3C"
    />
    <path
      d="M101.49 40.6901H108.495V41.6901H102.635V44.5051H108.125V45.4901H102.635V48.9501H108.71V49.9501H101.495V40.6901H101.49Z"
      fill="#180C3C"
    />
  </svg>
</template>
