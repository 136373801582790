<template>
  <div
    class="
      bg-white
      drop-shadow-xl
      rounded-[35px]
      mt-12
      py-[50px]
      md:py-[102px]
      px-[61px]
      md:px-[108px]
      xl:px-[125px]
      max-w-[792px]
      mx-auto
    "
  >
    <h4
      class="
        text-lg
        md:text-[26px]
        text-deepBlue-500 text-center
        font-bold
        mb-6
        md:mb-12
      "
    >
      Are you 18-64 years old?
    </h4>
    <div
      class="
        flex flex-col
        md:flex-row md:justify-between
        space-y-6
        md:space-y-0
        mt-0.5
      "
    >
      <button
        class="
          flex flex-col
          items-center
          py-10
          px-20
          md:px-24
          rounded-[35px]
          border-2 border-bgGray
          hover:border-customBlue-500
          focus:border-customBlue-500
          text-deepBlue-200
          hover:text-deepBlue-500
          focus:text-deepBlue-500
          text-xl
          font-ubuntu font-bold
        "
        @click="$emit('yes')"
      >
        <GreenCheck class="mb-3" /> Yes
      </button>
      <button
        class="
          flex flex-col
          items-center
          py-10
          px-20
          md:px-24
          rounded-[35px]
          border-2 border-bgGray
          hover:border-customBlue-500
          focus:border-customBlue-500
          text-deepBlue-200
          hover:text-deepBlue-500
          focus:text-deepBlue-500
          font-ubuntu font-bold
        "
        @click="$emit('no')"
      >
        <RedX class="mb-3" /> No
      </button>
    </div>
  </div>
</template>

<script>
import GreenCheck from "@/components/GreenCheck.vue";
import RedX from "@/components/RedX.vue";

export default {
  name: "Quiz1",
  components: {
    GreenCheck,
    RedX,
  },
  emits: ["yes", "no"],
};
</script>